import React from "react";
import { Link, withPrefix } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import homeRight from "../images/home-right.jpg";

const AboutUs = () => {
    return (
        <Layout headerClass="bg-white relative">
            <SEO title="AboutUs" />
            <div
                className="min-h-screen pt-12  bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center"
                style={{}}
            >
                <div className="flex flex-start mb-10">
                    <h1 className="text-3xl md:text-5xl text-indigo-700 leading-tight">
                        About A&F Pro Painting
                    </h1>
                </div>
                <div className="flex flex-wrap">
                    <div class="grid grid-cols-2 gap-4">
                        <div class="sm:col-span-3 md:col-auto col-span-3">
                            <img alt='home-right' width="100%" style={{ height: "100%" }} className="rounded" src={withPrefix(homeRight)} />
                        </div>
                        <div class="sm:col-span-3 md:col-auto col-span-3">
                            <img alt='home-right' width="100%" style={{ height: "100%" }} className="rounded" src={withPrefix(homeRight)} />
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4 mt-4">
                        <div class="sm:col-span-3 md:col-auto col-span-3">
                            <p className="mb-2 text-2xl text-gray-1000  self-center">
                                Your Friendly Local Painters
                            </p>
                            <p className="text-base" style={{ textAlign: 'justify' }}>
                                Everyone wants their home or business to look its best, but it can be hard to choose which
                                painting company is right for you. We believe that the best testament to quality is our many
                                happy customers. But you’re more than just a customer to us––you’re a neighbor. And we always
                                treat our neighbors with respect. We stand behind every job we do and treat every building like
                                it were our own. That’s why we’re proud to be a team of local painters.
                            </p>
                        </div>
                        <div class="sm:col-span-3 md:col-auto col-span-3">
                            <p className="mb-2 text-2xl text-gray-1000  self-center">
                                Best Painting Services
                            </p>
                            <p className="text-base" style={{ textAlign: 'justify' }}>
                                Do you want to give your home a much-needed makeover, or touch up a few imperfections?
                                Your home is in good hands with A & F Pro Painting. If you’re looking for the best painting
                                contractors in your area, look no further. We’re fully trained with years of experience.
                                Our time in the field gives us the skills needed to give you the best possible service.
                            </p>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4 mt-20">
                        <div class="sm:col-span-3 md:col-auto col-span-3">
                            <p className="mb-2 text-2xl text-gray-1000  self-center">
                                Professional Painters With A Positive Attitude
                            </p>
                            <p className="text-base" style={{ textAlign: 'justify' }}>
                                Commercial painting is an important task, and no one understands that better than the experts here at
                                A & F Pro Painting. As a small business, we know the meaning of presenting a public face to the world.
                                The appearance of your home or business is a reflection of you and your standards. If you want to make
                                a good impression, don’t leave it to the amateurs. Call A & F Pro Painting. We’re a team of professional
                                painters with high-standards and good work ethics. We’ll give you the professional painting services you
                                need at a fair price, and the respect you deserve.
                            </p>
                            <br />
                            <p className="mb-2 text-2xl text-gray-1000  self-center">
                                Quality Painting Services
                            </p>
                            <p className="text-base" style={{ textAlign: 'justify' }}>
                                We offer a wide variety of painting services, from commercial to residential, as well as interior and exterior.
                                Feel free to call us or browse our website to learn about all the great services we offer. Every building we paint
                                is a testament to our hard work, honesty, and commitment to quality. We’re proud of our dedication to quality and
                                detail. We only use the best quality tools and materials, and we always leave your place looking clean and tidy—that’s
                                the kind of service you can expect from A & F Pro Painting.
                            </p>
                            <br />
                            <p className="mb-2 text-2xl text-gray-1000  self-center">
                                Best Local Painting Contractors
                            </p>
                            <p className="text-base" style={{ textAlign: 'justify' }}>
                                Hiring a proudly local company like A & F Pro Painting means personalized attention and commitment. As a small
                                business, we don’t believe in rush jobs or cookie-cutter work. We take pride in beautifying our community.
                                That’s why we’ll give you something that’s just right for you. And we’ll always give it a hundred percent.
                            </p>
                            <br />
                            <p className="text-base" style={{ textAlign: 'justify' }}>
                                Whether you’re looking to spruce up your place or give it a complete makeover, we can make your vision come to life.
                                Our staff will listen to your ideas and use our expertise to give you something that fits your needs.
                                We’ll give you something we can stand behind.
                            </p>
                            <br />
                            <p className="text-base" style={{ textAlign: 'justify' }}>
                                You need local painting contractors you can trust: you need A & F Pro Painting.
                            </p>
                        </div>
                        <div class="sm:col-span-3 md:col-auto col-span-3">
                            <img alt='home-right' width="100%" style={{ height: "100%" }} className="rounded" src={withPrefix(homeRight)} />
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AboutUs;
